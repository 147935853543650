import React from "react";
import { Link } from "react-router-dom";
const ReservationMenu = () => {
  return (
    <ul className="rsv_side_menubar">
      <li
        className={`${
          window.location.pathname.includes(
            "/dashboard/reservation/reservation-panel"
          )
            ? "active"
            : ""
        }`}
      >
        <Link
          className="d-flex flex-column align-items-center "
          to="/dashboard/reservation/reservation-panel"
        >
          <span>
            <i class="fas fa-calendar-check"></i>
          </span>
          <span className="title">rsv</span>
        </Link>
      </li>
      <li
        className={`${
          window.location.pathname.includes(
            "/dashboard/reservation/waitlist-panel"
          )
            ? "active"
            : ""
        }`}
      >
        <Link
          className="d-flex flex-column align-items-center"
          to="/dashboard/reservation/waitlist-panel"
        >
          <span>
            <i class="far fa-list-alt"></i>
          </span>
          <span className="title">waitlist</span>
        </Link>
      </li>
      <li
        className={`${
          window.location.pathname.includes("/dashboard/reservation/seated")
            ? "active"
            : ""
        }`}
      >
        <Link
          className="d-flex flex-column align-items-center"
          to="/dashboard/reservation/seated"
        >
          <span>
            <i class="fas fa-chair"></i>
          </span>
          <span className="title">seated</span>
        </Link>
      </li>
      <li
        className={`${
          window.location.pathname.includes("/dashboard/reservation/history")
            ? "active"
            : ""
        }`}
      >
        <Link
          className="d-flex flex-column align-items-center"
          to="/dashboard/reservation/history"
        >
          <span>
            <i class="fas fa-sync"></i>
          </span>
          <span className="title">history</span>
        </Link>
      </li>
      <li
        className={`${
          window.location.pathname.includes("/dashboard/reservation/section")
            ? "active"
            : ""
        }`}
      >
        <Link
          className="d-flex flex-column align-items-center"
          to="/dashboard/reservation/section"
        >
          <span>
            <i class="fas fa-th"></i>
          </span>
          <span className="title">sections</span>
        </Link>
      </li>
    </ul>
  );
};

export default ReservationMenu;
