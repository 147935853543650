import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../../../BaseUrl";
import { _t, getCookie } from "../../../../../functions/Functions";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // p: 4,
};

const AssignTable = () => {
  const { t } = useTranslation();
  // states
  const [allTables, setallTables] = useState(null);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showAssign, setshowAssign] = useState();
  const [showNotify, setshowNotify] = useState(false);
  const [showCheck, setshowCheck] = useState(false);

  // table info
  const [tableInfo, settableInfo] = useState({});

  //functions
  // get all tables
  const getAllTables = () => {
    setLoading(true);
    const tableUrl = BASE_URL + `/settings/all-table`;
    return axios
      .get(tableUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallTables(res.data[1]);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handletableOpen
  const handleOptiontableStatus = (status) => {
    const tableUrl = BASE_URL + `/settings/instant-table-status`;
    const formData = new FormData();
    formData.append("status", status);
    formData.append("table_id", tableInfo.id);
    return axios
      .post(tableUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success(`${_t(t("Table status saved successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handleTableStatus function  to change table status
  const handleTableStatus = (table) => {
    settableInfo(table);
    handleOpen(true);
  };

  // handle booked table function
  const handleBookedTable = () => {
    toast.info(`${_t(t("Table is already booked!!"))}`, {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
    // setLoading(true);
  };

  // handle handleSeated function
  const handleSeated = () => {
    console.log("send seated status to server");
  };

  // handle handletabledirty function
  const handletabledirty = () => {
    console.log("send dirty status to server");
  };

  // useEffect
  useEffect(() => {
    getAllTables();
  }, []);
  return (
    <>
      {/* table option modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}

          {/* modal test start */}

          <div className="party_rsv_option table-book-option">
            {/* menu counter */}
            <span className="text-center d-block mb-3">
              {showNotify == true ? 2 : showCheck == true ? 3 : 1} of 3
            </span>
            {/* title box */}
            <div className="title-box">party option</div>
            {/* menu option */}
            <div className="party-menu-wrapper mt-4 d-flex justify-content-center">
              <div
                onClick={() => handleOptiontableStatus("open")}
                className={`single-table-option`}
              >
                <span className="icon d-block">
                  <i className="fas fa-laptop"></i>
                </span>
                <span className="title d-block">open</span>
              </div>
              <div
                onClick={() => handleOptiontableStatus("booked")}
                className={`single-table-option  mx-4`}
              >
                <span className="icon d-block">
                  <i className="fas fa-wifi"></i>
                </span>
                <span className="title d-block">seated</span>
              </div>
              {/* <div
                onClick={() => handletabledirty()}
                className={`single-table-option`}
              >
                <span className="icon d-block">
                  <i className="fas fa-chair"></i>
                </span>
                <span className="title d-block">dirty</span>
              </div> */}
            </div>
            {/* open */}
            <div className="assign-box-wrapper mt-3 p-4">
              {/* title info */}
              <div className="title-box">guest information</div>
              {/* info */}
              <div className="d-flex flex-wrap py-4">
                <span className="d-inline-block mr-3">
                  <i className="fas fa-clipboard-list"></i>
                </span>
                <p>No notes</p>
              </div>
              {/* today button */}
              <span className="day">today</span>
              {/* details */}
              <div className="info-wrapper py-3 mb-2">
                <span className="d-inline-block mr-3">
                  <i className="fas fa-circle"></i>
                </span>
                <span>Waited 3 mins of 10-15 min</span>
              </div>
              <div className="info-wrapper py-3 mb-2">
                <span className="d-inline-block mr-3">
                  <i className="fas fa-circle"></i>
                </span>
                <span>Added at 2.25 PM</span>
              </div>
              {/* action button */}
              <div className="d-flex justify-content-between mt-3">
                <button
                  onClick={() => handleSeated()}
                  className="btn btn-green"
                >
                  remove
                </button>
                <button className="btn btn-green">edit</button>
              </div>
            </div>
          </div>

          {/* modal test end */}
        </Box>
      </Modal>

      <div className="rsv_right_table_plan_wrapper overflow-y-scroll w-100 height-100vh">
        {allTables &&
          allTables.map((table, index) => {
            return (
              <>
                <div
                  onClick={(e) =>
                    table.status === "booked"
                      ? handleBookedTable()
                      : handleTableStatus(table)
                  }
                  className={`single-table-layout mt-4 
           ${
             table.capacity > 10
               ? "large-table"
               : table.capacity > 6
               ? "traingle-table mx-4"
               : "circle-table"
           }
           ${table.status === "booked" ? "booked" : ""}
           m-1`}
                  key={index}
                >
                  <span className="title">{table.name}</span>
                  <span className="d-inline-block text-white">
                    {table.capacity}
                  </span>
                </div>
              </>
            );
          })}
      </div>
    </>
  );
};

export default AssignTable;
