import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReservationMenu from "./layout/ReservationMenu";
import { sidebarSkeleton } from "./Skeleton/Skeleton";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography, Modal } from "@mui/material";

// context consumer
import { RestaurantContext } from "../../../../contexts/Restaurant";

// functions
import { _t, getCookie } from "../../../../functions/Functions";

// base url
import { BASE_URL } from "../../../../BaseUrl";

// axios
import axios from "axios";
import { toast } from "react-toastify";
import AssignTable from "./component/AssignTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const History = () => {
  const { t } = useTranslation();

  const [showAssign, setshowAssign] = useState();
  const [showNotify, setshowNotify] = useState(false);
  const [showCheck, setshowCheck] = useState(false);
  const [showOptionList, setshowOptionList] = useState(null);
  const [checkoutopen, setcheckoutOpen] = useState({
    open: false,
    table_group_id: null,
  });
  const [removeWaitList, setremoveWaitList] = useState({
    open: false,
    id: null,
  });

  //  checkout confirm modal
  const handlecheckoutopen = (table_group_id) => {
    if (table_group_id === null) {
      toast.error(`${_t(t("Please assign table first!!!"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      setcheckoutOpen({
        open: true,
        table_group_id: table_group_id,
      });
    }
  };
  const handlecheckoutClose = () =>
    setcheckoutOpen({
      open: false,
    });

  // remove waitlist confirm modal
  const handleRemoveOpen = (id) => {
    setremoveWaitList({
      open: true,
      id: id,
    });
  };
  const handleRemoveClose = () => {
    setremoveWaitList({
      open: false,
    });
  };

  // handle show option
  const handleShowOptions = (e) => {
    if (e === "assign") {
      setshowAssign(true);
      setshowNotify(false);
      setshowCheck(false);
    } else if (e === "notify") {
      setshowAssign(false);
      setshowNotify(true);
      setshowCheck(false);
    } else if (e === "check") {
      setshowAssign(false);
      setshowNotify(false);
      setshowCheck(true);
    }
  };

  //  todaylist
  const [todayList, settodayList] = useState([]);

  //  todaylist
  const [upcommingList, setupcommingList] = useState([]);

  // get today date
  const [TodayDate, setTodayDate] = useState(null);

  // loading
  const [loading, setLoading] = useState(false);

  // get current time
  const setCurentTimeDate = () => {
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    let newdate = year + "/" + month + "/" + day;
    setTodayDate(newdate);
  };

  // get today reservation list
  const getWaitlistReservations = () => {
    setLoading(true);
    const $todayUrl = BASE_URL + `/settings/get-all-canceled-rsv`;
    return axios
      .get($todayUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        settodayList(res.data["today"]);
        setupcommingList(res.data["upcomming"]);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
        setLoading(false);
      });
  };

  // notify customer
  const notifyCustomer = (id, msg) => {
    setLoading(true);
    const notifyUrl = BASE_URL + `/settings/notify-customer`;
    const formData = new FormData();
    formData.append("customer_id", id);
    formData.append("message", msg);
    return axios
      .post(notifyUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success(`${_t(t("Notification successfully sent"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`${_t(t("Please Try again!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        console.log(err);
        setLoading(false);
      });
  };

  // handle checkin
  const handleCheckin = (table_group_id, customer_id) => {
    if (table_group_id === null) {
      toast.error(`${_t(t("Please assign table first!!!"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      setLoading(true);
      const notifyUrl =
        BASE_URL + `/settings/checkedin-customer/${customer_id}`;
      const formData = new FormData();
      formData.append("table_group_id", table_group_id);
      return axios
        .post(notifyUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          // update waitlist value
          settodayList(res.data["today"]);
          setupcommingList(res.data["upcomming"]);
          toast.success(`${_t(t("Reservation booked successfully!!!"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          setLoading(false);
        })
        .catch((err) => {
          toast.error(`${_t(t("Please Try again!!"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          console.log(err);
          setLoading(false);
        });
    }
  };

  // handle show option list
  const handleOptionListOpen = (idx) => {
    setshowOptionList(idx);
  };

  // handle close option list
  const handleOptionListClose = (idx) => {
    setshowOptionList(null);
  };

  //handleRestore
  const handleRestore = (id) => {
    setLoading(true);
    const restoreUrl = BASE_URL + `/settings/restore-customer`;
    const formData = new FormData();
    formData.append("customer_id", id);
    return axios
      .post(restoreUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success(`${_t(t("Customer restored successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        // update waitlist value
        settodayList(res.data["today"]);
        setupcommingList(res.data["upcomming"]);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`${_t(t("Please Try again!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        console.log(err);
        setLoading(false);
      });
  };

  //handleDelete
  const handleDeleteCustomer = () => {
    setLoading(true);
    const restoreUrl = BASE_URL + `/settings/delete-customer`;
    const formData = new FormData();
    formData.append("customer_id", removeWaitList.id);
    return axios
      .post(restoreUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        toast.success(`${_t(t("Customer restored successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        // close modal
        setremoveWaitList({
          open: false,
        });

        // update waitlist value
        settodayList(res.data["today"]);
        setupcommingList(res.data["upcomming"]);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`${_t(t("Please Try again!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        console.log(err);
        setLoading(false);
      });
  };

  // useEffect
  useEffect(() => {
    setshowAssign(true);
    getWaitlistReservations();
    setCurentTimeDate();
  }, []);

  return (
    <>
      {/* remove waitlist list */}
      <Modal
        open={removeWaitList.open}
        onClose={handleRemoveClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure?
          </Typography>
          <Box className="mt-4 d-flex">
            <button
              className="btn btn-primary px-4 mr-4"
              onClick={() => handleDeleteCustomer()}
            >
              Yes
            </button>
            <button
              className="btn btn-success  px-4"
              onClick={handleRemoveClose}
            >
              No
            </button>
          </Box>
        </Box>
      </Modal>

      {/* wraper */}
      <div className="reservation_wrapper pt-3">
        {/* left side reservation menu */}
        <ReservationMenu />
        {/* right side reservation content*/}
        <div className="rsv_right_content">
          {/* top header-bar */}
          <div className="top-header d-flex align-items-center justify-content-between">
            <div className="date-wrapper">
              <span className="icon">
                <i class="fas fa-calendar-day"></i>
              </span>
              <span className="day">
                {/* Tuesday, May 2022 */}
                {TodayDate}
              </span>
            </div>
            <div>
              <Link
                to="/dashboard/reservation/add-party"
                className="btn btn-primary text-capitalize"
              >
                add party
              </Link>
            </div>
          </div>
          {/* bottom content */}
          <div className="rsv_btm_content container h-100 w-100">
            <div className="row w-100 justify-content-center align-items-center">
              <div className="col-md-4">
                <Link
                  to="/dashboard/reservation/today-history"
                  className="card shadow rounded p-5 text-decoration-none font-weight-bold text-center"
                >
                  Today
                </Link>
              </div>
              <div className="col-md-4">
                <Link
                  to="/dashboard/reservation/upcomming-history"
                  className="card shadow rounded p-5 text-decoration-none font-weight-bold text-center"
                >
                  Upcomming
                </Link>
              </div>
              <div className="col-md-4">
                <Link
                  to="/dashboard/reservation/expired-history"
                  className="card shadow rounded p-5 text-decoration-none font-weight-bold text-center"
                >
                  Expired
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
