import axios from "axios";

// get saas info
const checkSaasActive = async () => {
  const url = BASE_URL + "/check-saas-active-and-demo";
  await axios
    .get(url)
    .then((res) => {
      saas_apiUrl = window.location.protocol + "//" + res.data[0];
      SAAS_APPLICATION = res.data[1];
      demo = res.data[2];
    })
    .catch((res) => {
      console.log(res);
    });
};

// config
let production = "YES"; //YES ==live NO ==localhost
export let demo = "NO"; //YES/NO
export let SAAS_APPLICATION = ""; //YES|NO

// default config for saas application
export const saas_key =
  "$2a$12$pkLmD5jZdxd6bSNcTg9YT.g2mXz5gS8JTArdIg68c8RK/d7w2y2Gu";
export let BASE_URL = "";
export let saas_apiParams = "";
export let saas_form_data = "";
export let saas_apiUrl = "";

// google recapture
export let RECAPTURE_SITEKEY = "";

if (production === "YES") {
  //for production
  BASE_URL =
    window.location.protocol +
    "//" +
    window.location.hostname +
    `${
      window.location.port !== ""
        ? `:${window.location.port}`
        : `${!window.location.href.includes(".test") ? "/public" : ""}`
    }`;

  //saas config
  // saas_apiUrl = 'https://automatefood.com';
  saas_apiParams = `saas_key=${saas_key}&domain=${BASE_URL}`;
  saas_form_data = {
    saas_key: saas_key,
    domain: BASE_URL,
  };
} else {
  // for localhost development
  BASE_URL =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/khadyo_2_4/laravel/public";

  // saas config
  // saas_apiUrl = 'http://127.0.0.1:8000';
  saas_apiParams = `saas_key=${saas_key}&domain=mumma.thetestserver.xyz`;
  saas_form_data = {
    saas_key: saas_key,
    // 'domain': 'prince.automatefood.com'
    domain: "mumma.thetestserver.xyz",
    // 'domain': 'ewqeqw.thetestserver.xyz'
  };
}
checkSaasActive();
