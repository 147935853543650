import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReservationMenu from "./layout/ReservationMenu";
import {
  Box,
  Modal,
  Button,
  Typography,
  TextField,
  Popover,
} from "@mui/material";
import { width } from "@mui/system";
import { useTranslation } from "react-i18next";
import NewSectionModal from "./modals/NewSectionModal";
import { BASE_URL } from "../../../../BaseUrl";
import { _t, getCookie } from "../../../../functions/Functions";
import axios from "axios";
import { toast } from "react-toastify";
import AssignTable from "./component/AssignTable";

const Section = () => {
  const { t } = useTranslation();
  const [showAssign, setshowAssign] = useState();
  const [showNotify, setshowNotify] = useState(false);
  const [showCheck, setshowCheck] = useState(false);
  const [sectionName, setsectionName] = useState(null);
  const [allSections, setallSections] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [sectionHoverAction, setsectionHoverAction] = useState(-1);
  // modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
  };

  // handle modal show options
  const handleShowOptions = (e) => {
    if (e === "assign") {
      setshowAssign(true);
      setshowNotify(false);
      setshowCheck(false);
    } else if (e === "notify") {
      setshowAssign(false);
      setshowNotify(true);
      setshowCheck(false);
    } else if (e === "check") {
      setshowAssign(false);
      setshowNotify(false);
      setshowCheck(true);
    }
  };

  //section option hover
  const showsectionOptionHover = (i) => {
    setsectionHoverAction(i);
  };
  // section option hover
  const hidesectionOptionHover = () => {
    setsectionHoverAction(-1);
  };

  // handle section delete
  const handleSectionDelete = (id) => {
    const checkDateUrl = BASE_URL + `/settings/delete-rsv-section/${id}`;

    return axios
      .get(checkDateUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log(res);
        setallSections(res.data.data);
        setLoading(false);
        toast.success(`${_t(t("Section deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
        toast.error(`${_t(t("Sorry!! Something went wrong"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  // modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // onChangeSection
  const onChangeSection = (e) => {
    setsectionName(e.target.value);
  };

  //  add section name
  const handleAddSection = (e) => {
    e.preventDefault();
    const sendUrl = BASE_URL + `/settings/add-rsv-section`;

    const data = {
      name: sectionName,
    };

    axios
      .post(sendUrl, data, {
        headers: {
          Authorization: `Bearer ${getCookie()}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setOpen(false);
        setallSections(res.data.data);
        toast.success(`${_t(t("Section added successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  // edit section name
  const handleSectionEdit = (id) => {
    alert(id);
    // will be added in future update
  };

  // get all sections
  const getAllSections = () => {
    setLoading(true);
    const sectionUrl = BASE_URL + `/settings/get-section-details`;
    return axios
      .get(sectionUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallSections(res.data);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // openAssignServerModal
  const openAssignServerModal = () => {
    console.log("heeeeeelo");
  };

  // useEffect
  useEffect(() => {
    setshowAssign(true);
    getAllSections();
  }, [sectionName]);

  return (
    <>
      {/* new section moddal */}
      <NewSectionModal
        handleAddSection={handleAddSection}
        open={open}
        handleClose={handleClose}
        onChangeSection={onChangeSection}
        style={style}
      />
      {/* server assign modal */}
      {/* <Modal
        open={serverModalOpen}
        onClose={closeServerModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component="form" onSubmit={handleAddSection} sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add section
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField
              sx={{ width: "100%" }}
              id="standard-basic"
              label="Section name"
              variant="standard"
              onChange={onChangeSection}
            />
            <Button
              type="submit"
              sx={{
                marginTop: "10px",
                textTransform: "capitalize",
                display: "block",
              }}
              variant="contained"
            >
              done
            </Button>
          </Typography>
        </Box>
      </Modal> */}

      {/* wraper */}
      <div className="reservation_wrapper pt-3">
        {/* reservation menu */}
        <ReservationMenu />
        {/* right side reservation content*/}
        <div className="rsv_right_content">
          {/* top header-bar */}
          <div className="top-header d-flex align-items-center justify-content-between">
            <div className="date-wrapper">
              <span className="icon">
                <i className="fas fa-calendar-day"></i>
              </span>
              <span className="day">Tuesday, May 2022</span>
            </div>
            <div>
              <Link
                to="/dashboard/reservation/add-party"
                className="btn btn-primary text-capitalize"
              >
                add party
              </Link>
            </div>
          </div>
          {/* bottom content */}
          <div className="rsv_btm_content">
            {/* inner left side */}
            <div className="rsv_left_btm_sidebar position-relative overflow-y height-100vh">
              <div className="section-header-table-area d-flex bg-lite-green p-3 justify-content-between align-items-center position-abs-left-top-0 w-100">
                <span className="text-capitalize">layout name</span>
                <span className="text-capitalize option">options</span>
              </div>
              {/* current */}
              <span className="text-capitalize d-inline-block my-3">
                open sections - {allSections && allSections.length}
              </span>

              {/* reservation list */}
              {allSections &&
                allSections.map((sections, i) => {
                  return (
                    <>
                      <div
                        onMouseLeave={hidesectionOptionHover}
                        onMouseEnter={() => showsectionOptionHover(i)}
                        className="rsv_party_list position-static mb-4"
                        key={sections.id}
                      >
                        <div className="d-flex align-items-center">
                          <span className="total-size mr-3">5</span>
                          <span className="customer-name">{sections.name}</span>
                        </div>
                        {/* party option for single waitlist  start*/}
                        <div
                          style={
                            sectionHoverAction === i
                              ? {
                                  display: "block",
                                }
                              : {
                                  display: "none",
                                }
                          }
                          className="party_rsv_option position-static table-book-option px-4"
                        >
                          <span className="text-center d-block mb-3">
                            {sections.name}
                          </span>
                          <div className="title-box d-none">
                            section options
                          </div>
                          <div className="party-menu-wrapper mt-4">
                            <span className=" d-block w-100 my-2 text-capitalize text-success title-box">
                              table assigned 0
                            </span>
                            <Link
                              to={`/dashboard/reservation/assign-tables/${sections.id}`}
                              className="btn btn-green text-uppercase d-block w-100 px-4 mt-4"
                            >
                              add tables
                            </Link>
                          </div>
                          <div className="party-menu-wrapper mt-4">
                            <span className=" d-block w-100 my-2 text-capitalize text-success title-box">
                              server assigned
                            </span>
                            <button
                              onClick={() => openAssignServerModal()}
                              className="btn btn-green text-uppercase d-block w-100 mt-4"
                            >
                              assign server
                            </button>
                          </div>

                          <div className="d-flex justify-content-between  py-4">
                            <button
                              onClick={() => handleSectionDelete(sections.id)}
                              className="btn btn-green bg-danger"
                            >
                              delete
                            </button>
                            {/* <button
                              onClick={() => handleSectionEdit(sections.id)}
                              className="btn btn-green bg-info"
                            >
                              edit
                            </button> */}
                          </div>
                        </div>
                        {/* party option for single waitlist end*/}
                      </div>
                    </>
                  );
                })}

              {/*  footer menu*/}
              <div
                className="section-header-table-area d-flex bg-lite-green p-3 justify-content-between align-items-center
              position-abs-left-btm-0 w-100
            "
              >
                {/* trigger button */}
                <Button
                  sx={{
                    color: "#00e676",
                  }}
                  onClick={handleOpen}
                >
                  add section
                </Button>
                {/* <span className="text-capitalize">add section</span> */}
                <span className="text-capitalize option">server</span>
              </div>
            </div>

            {/* inner right side */}
            <AssignTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;
